.header{
    background-color: white;
    color: black;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 0rem;
    margin: 0;
    padding: 2.5rem;


    &--logo{
        font-family: "Montserrat", sans-serif;
        padding: 0rem;
        margin-left:0.5rem;
        float: leftt;
        text-align: left;
        font-size: 1.5rem;
        padding: 0.5rem;
        text-decoration: none;
        color: white;
        z-index: -99;

        #fabrica{
          color: white;
          font-weight: 600;
          font-size:1.4rem;
        }
        
        @media (max-width: $break-medium){
          visibility: hidden;
        }
    }

    &--content{
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        padding: 0rem;
        margin-right:1rem;
        float: right;
        text-align: right;

        a{
            font-size: 1.1rem;
            padding: 0.7rem;
            display: inline;
            position: relative;
            overflow: hidden;
        }
        
        a:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 8%;
            right: 100%;
            bottom: 0.2em;
            background: $main-red;
            height: 0.08em;
            transition-property: left right;
            transition-duration: 0.25s;
            transition-timing-function: ease-out;
          }
          a:hover:after, a:focus:after, a:active:after, a:visited:after {
            right:6.5%;
          }

        a:link, a:visited{
            color: black;
            text-decoration: none;
        }

        @media (max-width: $break-medium){
            visibility: hidden;
            position:absolute;
        }

    }

    &--mobile{
        font-family: "Montserrat", sans-serif;
        float: right;
        visibility: hidden;

        .container {
            display: inline-block;
            background: transparent;
            cursor: pointer;
          }

        .bar1, .bar2, .bar3 {
            width: 35px;
            height: 4px;
            background-color: $main-red;
            margin: 6px 0;
            transition: 0.4s;
            border-radius: 0px;
            z-index: 14;
          }
          
          .change .bar1 {
            -webkit-transform: rotate(-135deg) translateY(-7px) translateX(-7px);
            transform: rotate(-135deg) translateY(-7px) translateX(-7px);
            background-color: $main-red;
          }
          
          .change .bar2 {
              opacity: 0;
              background-color: $main-red;
          }
          
          .change .bar3 {
            -webkit-transform: rotate(135deg) translateY(7px) translateX(-7px);
            transform: rotate(135deg) translateY(7px) translateX(-7px);
            background-color: $main-red;
          }

        .overlayMenu{
            position: fixed;
            background: $not-black;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            opacity: 0;
            visibility: hidden;
            transition: opacity .35s, visibility .35s, height .35s;
            overflow: hidden;
            opacity: .4;
            height: 10%;
            z-index: -10;

            &.open {
                opacity: 1;
                visibility: visible;
                height: 100%;
                
                li {
                  color: white;
                  animation: fadeInRight .5s ease forwards;
                  animation-delay: .35s;
                  
                  &:nth-of-type(2) {
                    animation-delay: .4s;
                  }
                  &:nth-of-type(3) {
                    animation-delay: .45s;
                  }
                  &:nth-of-type(4) {
                    animation-delay: .50s;
                  }
                  
                }
            }

            ul {
                position: absolute;
                font-size: 2em;
                width: 100%;
                height: 50%;
                text-align: center;
                top: 25%;
                left: 0;
                margin:  0 auto;
                list-style: none;
                padding: 0;
                margin: 0 auto;
                display: inline-block;
                position: relative;

                #ig{
                  display: inline-block;
                  padding-top: 2em;
                  padding-right: 0.5em;
                }

                #li{
                  padding-top: 2em;
                  display: inline-block;
                }
            }
    
            li {
                display: block;
                height: 25%;
                height: calc(100% / 4);
                min-height: 50px;
                position: relative;
                opacity: 0;

                
                a {
                  display: block;
                  position: relative;
                  color: white;
                  text-decoration: none;
                  overflow: hidden;
                  -webkit-transition:.5s;
                  transition:.5s;

                  &:hover,
                   &:focus,
                   &:active {
                       color: $summer-primary;
                   }
                  
                  &:hover:after,
                   &:focus:after,
                   &:active:after {
                     width: 100%;
                     color: $summer-primary;
                   }
                  
                  &:after {
                    content: '';
                    position: relative;
                    bottom: 0;
                    left: 50%;
                    width: 0%;
                    transform: translateX(-50%);
                    height: 3px;
                    background: $summer-primary;
                    transition: .35s;
                  }
                }
              }
    
        }
       
        @media (max-width: $break-medium){
            visibility: visible;
        }
    }

    @media (max-width: $break-medium){
      padding: 1.8rem 1.5rem;
    }
}

@keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }