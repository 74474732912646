.intro {
    display: grid;
    background: white;
    grid-template-columns: 52% 48%;
    overflow: hidden;
    position: relative;
    clear: both;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    height: 100%;

    &--title{
      font-weight: 300;
      margin: -1vw 0vw 0vw 8.5vw;
      font-size: 18.5vh;
      color: black;
      line-height: 1.1em;
      letter-spacing: 0.30em;
      -webkit-animation: fade-in 2.0s cubic-bezier(0.445, 0.050, 0.550, 0.950) 0.2s both;
	    animation: fade-in 2.0s cubic-bezier(0.445, 0.050, 0.550, 0.950) 0.2s both;
      
      #we{
        margin-left: 0.3vw;
        font-size:15.5vh;
        letter-spacing: 0.10em;

        @media (max-width: $break-medium){
          font-size: 8.5vh;
          
        }
      }
      #are{
        margin-left: 0.8vw;
        font-size:16.5vh;

        @media (max-width: $break-medium){
          font-size: 9.0vh;
        }
      }
      #fab{
        font-weight: 300;
        color: $main-red;
        -webkit-animation: slide-in-right 2.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	      animation: slide-in-right 2.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;

        @media (max-width: $break-medium){
          font-size: 10.0vh;
          font-weight: 300;
        }
      }
      #rica{
        color: black;

        @media (max-width: $break-medium){
          font-size: 10.0vh;
        }
      }

      @media (max-width: $break-medium){ //GENERAL TITLE MEDIA BREAK
        margin: 6vw 5.5vw 0vw 5.5vw;
        line-height: 1.2em;
        font-size: 9.5vh;
      }
    }

    &--text{
      background-color: white;
      font-size: 2.8vw;
      display: grid;
      grid-template-rows: repeat(4, 25%);
      -webkit-animation: fade-in-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
	    animation: fade-in-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;

      a{
        color: black;
        text-decoration: none;
      }
      a:hover{
        color: $main-red;
        text-decoration: underline;
      }

      .container{
        grid-row: 3;
        background: white;
        border-left: 2.0px solid black;
       
        .text-container{
          margin: -4vw 0vw 0vw 3vw;

          #description{
            display: none;

            @media (max-width: $break-medium){
              display: block;
              line-height: 1.2em;
              
            }
          }

          #say{
            display: none;

            @media (max-width: $break-medium){
              display: inline;
              color: $main-red;
            }
          }
          #mail{
            display: inline;

            @media (max-width: $break-medium){
              display: inline;
              #word{
                color: $main-red;
              }
            }
          }

          #text{
            @media (max-width: $break-medium){
              display: none;
            }
          }

          #ig{
            display:none;

            @media (max-width: $break-medium){
              margin-top: 1em;
              text-decoration: underline;
              display: inline;

            }
          }
          #li{
            display:none;

            @media (max-width: $break-medium){
              text-decoration: underline;
              display: inline;
            }
          }

          @media (max-width: $break-medium){
            line-height: 2.0em;
            
            .fab{
              font-size: 1.3em;
              color: black;
              padding-right: 0.3em;
            }

          }
        }

        @media (max-width: $break-medium){
          grid-row:1;
          border-left: 0px;
          font-size: 6vw; //EMAIL FONT
        }
      }

      .separator{
        grid-row: 4 / span 4;
        border-left: 2.0px solid black;

        @media (max-width: $break-medium){
          display: none;
        }

      }

      @media (max-width: $break-medium){ //GENERAL TEXT MEDIA BREAK
        grid-column: 1;
        margin: -15.5vw 2.2vw 0vw 2.2vw;
      }

    }

    @media (max-width: $break-medium){ //GENERAL INTRO MEDIA BREAK
      grid-template-columns: repeat(1, 100%);
    }

}




@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
