.contact {
    background: $main-red;
    color: white;
    text-align: center;
    position: fixed;
    width: 100%;
    height: auto;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    
  
    &--heading {
      margin-bottom: 1.5rem;
      padding-top: 2.5rem;
      padding-left: 12.5rem;
      float: left;
      position: absolute;
  
      h2 {
        display: inline-block;
        margin: 0;
        text-align: left;
      }
  
      @media (max-width: $break-medium) {
        padding-top: 20px;
        padding: 1rem 0 0 1rem;
  
        h2 {
          border-top-width: 15px;
        }
      }
    }

    &--info{
      margin: 8rem 0rem 1.5rem 0rem;
      padding-top: 0rem;
      padding-left: 7.5rem;
      float: left;
      text-align: left;
      position: relative;
      font-family: "Montserrat", sans-serif;
      
      h2{
        font-size: 2.5vw;
        margin-bottom: 0.8em;
        color: white;
        font-weight: 400;

        @media (max-width: $break-medium) {
          font-size: 9vw;
        }
      }

      h3{
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: 1.5vw;
        line-height: 1.5em;
        font-weight: 300;

        @media (max-width: $break-medium) {
          font-size: 6vw;
        }
      }

      #number{
        margin-top: 0.2rem;
      }

      a{
        text-decoration: none;
        color: white;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }

      a:hover{
        color: $summer-primary;
      }

      @media (max-width: $break-medium) {
        margin: 0;
        margin-top: 5rem;
        float:left;
        padding-top: 20px;
        padding: 1rem 0 0 1rem;
      }
    }
  
    &--content {
      padding-bottom: 2rem;
      clear: both;
      margin: 3.5rem auto;
      padding: 6rem;
      list-style-type: none;
      text-align:center;
      width: 100%;
      position: absolute;
      z-index: -10;
  
      h3 {
        margin: 0;
      }

      @media (max-width: $break-medium){
        visibility: hidden;
      }
    }

    .fab{
      padding-right: 0.3em;
      font-size: 2.2vw;

      @media (max-width: $break-medium){
        display: none;
        font-size: 8vw;
      }
    }

    &--social {
      width: 1.5rem;
      text-align: left;
      text-decoration: none;
      margin-right: 1rem;
      background: transparent;
      color: white;
    }
  }
  
  .footer {
    display: inline-block;
    margin: 0 auto;
    padding: 1rem 1rem 0.5rem;
    color: white;
    font-size: 0.75em;
    width: 100%;

    p{
      margin: auto;
      //border-top: 1px solid black;
      padding: 1rem 0;
      width: 100%;
      text-align: center;
    }

    @media (max-width: $break-medium){
      padding-top: 2rem;
    }

  }