/**
 * SCREEN BREAKS
 */
 $break-small: 650px;
 $break-medium: 768px;
 $break-large: 1024px;
 
/* Colors */

// $main-red: #ff7171;
$main-red: #ff8989;
$summer-primary: rgb(248, 204, 58);
$summer-secondary: #FFFFFF;
$not-black: #141414;
$background: rgb(245, 63, 75);

/* Fonts */

$main-font: "Montserrat", sans-serif;
 
 /**
  * EXTENDS
  */
 
 %content-width {
   width: 90%;
   max-width: 1000px;
   margin: 0 auto;
 }
 
 %light-on-dark-text {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }