.about {
    @extend %light-on-dark-text;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    position: relative;
    font-family: $main-font;
    height: auto;


    &--text{
      grid-column: 1;
      font-size: 2.5vw;
      font-weight: 300;
      margin: 8vw 4vw 0vw 6vw;
      text-align: left;


      #hello{
        font-size: 6vw;
      }

      #dash{
        font-size: 8vw;
        display: block;
        color: $main-red;
        line-height: 0;
        padding-top: 2vw;
        padding-bottom: 3.5vw;
      }

      #hash{
        color: $main-red;
      }

      #text1{
        display: inline;
      }

      @media (max-width: $break-medium){ //MEDIA TEXT
        display: block;
        font-size: 7vw;
        margin: 0vw 5vw 22vw 5vw;
        text-align: left;

        #hello{
          font-size: 12vw;
          display: block;
          font-weight: 400;
          margin-bottom: 0.2em;
        }

        #dash{
          padding: 0;
          line-height: 10vw;
          margin: -4vw 0 0vw 0;
          font-size: 12vw;
          display: block;
          color: $main-red;
        }
        #text1{
          display: block;
        }
      }
    }

    &--text2{
      padding-top: 11.2vw;
      margin: 8vw 0vw 8vw 0vw;
      font-size: 1.5vw;
      font-weight: 300;
      color: black;
      display: grid;
      grid-column: 2;
      grid-template-rows: repeat(3, auto);
      text-justify: auto;
      text-align: center;

      .fas{
        display: none;
        font-size: 3.5vw;
        padding: 0.5vw;
        color: $main-red;

        @media (max-width: $break-medium){
          display: inline-block;
          font-size: 12vw;
        }
      }
     

      h3{
        font-size: 2vw;
        font-weight: 400;
        text-align: center;
        line-height: 0;

        @media (max-width: $break-medium){
          line-height: 6vw;
          font-size: 8vw;
          text-align: center;
          font-weight: 400;
          margin-bottom: 0.8em;
        }
      }

      #dash{
        display: block;
        font-size: 6vw;
        line-height: 0;
        color: $main-red;
        padding-top: 0.5vw;
        padding-bottom: 2vw;

        @media (max-width: $break-medium){
          padding-bottom: 5vw;
          font-size: 12vw;
          display: block;
          color: $main-red;
        }
      }

      .container{
        grid-row: 2;
        margin: 0vw 10vw 0vw 10vw;

        @media (max-width: $break-medium){
          margin: 0vw 8vw 12vw 8vw;
        }

      }

      .container2{
        grid-row: 3;
        margin: 2vw 10vw 0vw 10vw;

        @media (max-width: $break-medium){
          margin: 0vw 8vw 12vw 8vw;
        }
      }


      
      @media (max-width: $break-medium){ //MEDIA TEXT2
        display: unset;
        font-size: 6.5vw;
        text-align: center;
        margin: 0vw 5vw 0vw 5vw;
        
      }
    }

    @media (max-width: $break-medium){ //MEDIA ABOUT
      display: block;
    }
  
}