* {
    box-sizing: border-box;
  }
  
  html {
    font-family: $main-font;
  }
  
  
  body {
    height: 100vh;
    margin: 0;
    padding: 0;

    section{
      height: 200px;
      width: 200px;
      overflow: scroll;
    }
      
    

    .containerBody {
      background: white;
    }
  }
  
  h1 {
    font-size: 4.236em;
  }
  
  h2 {
    font-size: 2.618em;
  }
  
  h3 {
    font-size: 1.618em;
  }
  
  @media (max-width: $break-large) {
    h1 {
      font-size: 3.375em;
    }
  
    h2 {
      font-size: 2.25em;
    }
  
    h3 {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: $break-medium) {
    h1 {
      font-size: 2.369em;
    }
  
    h2 {
      font-size: 1.777em;
    }
  
    h3 {
      font-size: 1.333em;
    }
  }