.location {
    color: white;
    overflow: auto;
    position: relative;
    font-family: $main-font;
    color: black;
  
    &--heading {
      text-align: center;
      margin: 4vw 0vw 5vw 0vw;

      h3 {
        
        font-size: 3vw;
        font-weight: 400;
        margin-bottom: 0.2em;
        padding-top: 1rem;
        display: inline-block;
        text-align: center;
        

        @media (max-width: $break-medium){
          margin: 10vw 0vw 14vw 1vw;
          font-size: 10vw;
          display: block;
          font-weight: 400;
          text-align: left;
        }
        
      }
      
      #dash{
        display: none;
      }
  
      @media (max-width: $break-medium) {
        margin-bottom: 2rem;
        padding: 1rem 1rem 0 1rem;

        #dash{
          margin: -14vw 0 -4vw 0;
          font-size: 12vw;
          display: block;
          color: $main-red;
          text-align: left;
        }
      }

    }

    .mapTextContainer{
      display: none;
    }
  
    &--content {
      @extend %content-width;
      display: block;
      margin-top: 3rem;
      grid-column-gap: 4rem;
      grid-template-columns: [logo details] auto 1fr;
      max-width: 830px;
      padding-bottom: 6rem;
  
      @media (max-width: $break-medium) {
        display: block;
        max-width: 450px;
        margin-top: 2rem;
        padding-bottom: 3.5rem;
      }
    }

    &--map{
      height: 450px; 
      width: 100%; 

      @media (max-width: $break-medium) {
        padding: 0;
        height:400px;
      }
    }

    @media (min-width: $break-medium) {
      display: grid;
      grid-template-columns: 50% 50%;
      padding-left: 4vw;

      &--heading {
        text-align: left;
        
        h3{
          text-align: left;
          padding-left: 2.5vw;
        }

        #dash{
          padding-left: 2.5vw;
          font-size: 8vw;
          display: block;
          color: $main-red;
          line-height: 0;
          padding-top: 2vw;
          padding-bottom: 3.5vw;
          font-weight: 300;
        }
        
      }

      .mapContainer{
        grid-column: 1;
      }

      .mapTextContainer{
        display: block;
        grid-column: 2;
        margin: 31vw auto;

        >i{
          font-size: 8vw;
          color: $main-red;
          text-align: center;
          margin-left: 12vw;
          margin-bottom: 1vw;
        }

        >h1{
          text-align: center;
          font-size: 1.5vw;
        }
      }

    }

  }
  
  
